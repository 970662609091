
import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {mask} from "vue-the-mask";

@Component({
  directives: {
    mask,
  },
})
export default class Dates extends Vue {
  @Prop({}) value!: string;
  @Prop({}) label!: string;
  @Prop({}) placeHolder!: string;
  @Prop({}) type!: string;
  @Prop() color!: string;
  @Prop({default: true}) clearable!: boolean;
  @Prop({}) rules!: string;
  @Prop({default: false}) range!: boolean;
  @Prop({}) reverse!: any;
  @Prop({}) readonly!: boolean;
  @Prop({}) minDate!: Date;
  @Prop({}) maxDate!: Date;
  @Prop({}) dense !: string | boolean;
  @Prop({}) hideIcon !: string | boolean;
  @Prop({}) aciklama!: string;
  @Prop({}) persistentHint!: any;
  @Prop({}) hideDetails!: string | boolean;
  @Prop() error !: boolean;
  @Prop() errorMessages !: string;

  menu: boolean = false;
  mask = "##.##.####";
  date = this.value ? new Date(this.value) : null;
  pickerValue: string | null = this.date ? this.date.toISOString().substr(0, 10) : null;
  textFieldValue: string = "";

  get rule() {
    if (this.type === 'year') {
      return [];
    }
    if (this.textFieldValue) {
      if (this.textFieldValue.length == 10) {
        if (this.textFieldValue.match(/^(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](19|20)\d\d$/) !== null) {
          if (this.rules)
            return this.rules;
          else
            return [true]
        }
        else return ['Yanlış tarih bilgisi.']
      } else
        return ['Eksik tarih bilgisi'];
    }
    else{
      if(this.rules)
        return this.rules;
      else return [true]
    }
  }

  get isReverse() {
    return this.reverse || this.reverse === "";
  }

  get isPersistentHint() {
    return this.persistentHint || this.persistentHint === "";
  }

  get isHideDetails() {
    return this.hideDetails || this.hideDetails === "";
  }

  get isDense() {
    return this.dense || this.dense === "";
  }

  get isHideIcon() {
    return this.hideIcon || this.hideIcon === "";
  }

  @Watch("value")
  onValueChanged(newVal: string, oldVal: string) {
    if (newVal) {
      this.date = new Date(newVal);
      this.pickerValue = this.date.toISOString().substr(0, 10);
    } else {
      this.date = null;
      this.pickerValue = null;
    }
    this.onPickerValueChanged();
  }

  @Watch("pickerValue")
  onPickerValueChanged() {
    this.textFieldValue = this.pickerValue ? this.formatToText(this.pickerValue) : "";
    this.save();
  }

  @Watch("textFieldValue")
  onTextFieldValueChanged() {
    if (this.textFieldValue == null || this.textFieldValue == '') {
      this.pickerValue = null;
    } else if (this.textFieldValue.length == 10) {
      this.pickerValue = this.formatToDate(this.textFieldValue);
    }
    this.save();
  }

  mounted() {
    this.onPickerValueChanged();
  }

  formatToText(date: string): string {
    if (!date) return "";
    if (this.type === "year") {
      return date.split("-")[0]; // Only show the year
    }
    return date
      .split("-")
      .reverse()
      .join(".");
  }

  formatToDate(date: string): string {
    if (!date) return "";
    if (this.type === "year") {
      return `${date}-01-01`; // Set month and day to January 1st
    }
    return date
      .split(".")
      .reverse()
      .join("-");
  }

  save() {
    if (this.pickerValue) {
      this.$emit("input", this.pickerValue);
    } else {
      this.$emit("input", null);
    }
    this.menu = false;
  }
}
